import React from 'react'
import { graphql } from 'gatsby'
import { About } from '@/components'

export const pageQuery = graphql`
  query About($lang: String!, $tag: String = "about") {
    prismicAbout(lang: { eq: $lang }, tags: { eq: $tag }) {
      data {
        cases {
          case_item_description {
            html
            text
          }
          case_item_image {
            alt
            fluid {
              ...GatsbyPrismicImageFluid
            }
          }
          case_item_title {
            text
          }
          case_item_link
        }
        cases_cta_url
        cases_cta_text
        cases_description {
          html
          text
        }
        cases_title {
          text
        }
        count_items {
          count_description {
            text
          }
          count_number {
            text
          }
        }
        distributed_description {
          html
          text
        }
        distributed_title {
          text
        }
        distributed_video_link {
          url
        }
        hero_description {
          html
          text
        }
        hero_image {
          alt
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        hero_title {
          text
        }
        with_us_cta_url
        with_us_cta_text
        with_us_title {
          text
        }
      }
    }
    prismicMeta {
      data {
        social_image {
          url
        }
      }
    }
  }
`

const AboutPage = props => <About {...props} />

export default AboutPage
